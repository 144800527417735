import React, {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact, {BootstrapURLKeys, MapOptions, Maps} from 'google-map-react';

interface Coords {
    lat: number;
    lng: number;
}

interface GoogleMapPropsWithChildren {
    children?: ReactNode | ReactNode[];
    defaultZoom?: number;
    defaultCenter?: Coords;
    options?: (maps: Maps) => MapOptions;
    bootstrapURLKeys?: BootstrapURLKeys;
    yesIWantToUseGoogleMapApiInternals?: boolean;
    onGoogleApiLoaded?: ({ map, maps }: { map: Map<any, any>; maps: any }) => void;
    onChildClick?(hoverKey: any, childProps: any): void;
}

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

const GoogleMap: FC<GoogleMapPropsWithChildren> = ({ children, ...props }) => (
    <Wrapper>
        <GoogleMapReact
            bootstrapURLKeys={{key: 'AIzaSyBIqWy3JkxVNjorFXL1qe20Ylh_0NVQoZc'}}
            {...props}
        >
            {children}
        </GoogleMapReact>
    </Wrapper>
);

GoogleMap.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]) as React.Validator<ReactNode | ReactNode[]>,
};

GoogleMap.defaultProps = {
    children: null,
};

export default GoogleMap;